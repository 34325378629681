@import "bootstrap/scss/bootstrap.scss";
@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
@import 'variable';
@import 'cookieconsent/build/cookieconsent.min';

body {
	font-family: 'Roboto', sans-serif;
	overflow-x: hidden;
}

.custom-container {
	max-width: 1200px;
	padding-right: var(--bs-gutter-x, 0.75rem);
	padding-left: var(--bs-gutter-x, 0.75rem);
	margin-right: auto;
	margin-left: auto;
}

.font-primary {
	color: var(--primary-color)
}

.font-danger {
	color: var(--font-danger);
}

.font-16px {
	font-size: 16px;
}

.m--font-brand {
	color: var(--primary-color);
}

.header-top {
	background-color: var(--primary-color);
	padding: 10px 15px;

	.top-header-content {
		ul {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding-left: 0;

			li {
				list-style: none;

				a {
					color: var(--white-color);
					display: flex;
					align-items: center;
					font-size: 15px;

					span {
						font-size: 15px;
					}
				}

				+ li {
					padding-left: 30px;
				}
			}
		}
	}

	@media (max-width: 767px) {
		position: absolute;
		width: 0;
		height: 100vh;
		z-index: 55;
		background-color: var(--white-color);
		right: 0;
		top: 0;
		box-shadow: 0 0 4px 1px rgba($black, 0.1);
		transform: translateX(210px);
		opacity: 0;
		visibility: hidden;
		transition: all 0.3s;
		display: none;
		.top-header-content {
			ul {
				flex-direction: column;
				align-items: unset;
				justify-content: unset;

				li {
					a {
						color: var(--black-color);
					}

					+ li {
						padding-left: 0;
						margin-top: 10px;
					}
				}
			}

			.close {
				background-color: transparent;
				border: 0;
				font-size: 20px;
				margin: 15px 0 30px;
				width: fit-content;
			}
		}

		&.show {
			opacity: 1;
			visibility: visible;
			width: 200px;
			transform: translateX(0);
			display: block;
		}
	}
}

.header {
	background-color: var(--white-color);
	padding: 5px;
	color: var(--white-color);
	box-shadow: 0 0 4px 0 rgba($black, 0.4);

	.img-wrapper {
		img {
			max-height: 80px;
			@media (max-width: 991px) {
				max-height: 70px;
			}

			@media (max-width: 767px) {
				max-height: 60px;
			}
		}
	}

	.navbar-toggler {
		&:focus {
			outline: 0;
			box-shadow: none;
		}
	}

}

form {
	.m-form__group {
		.mat-form-border {
			border-bottom: 1px dashed var(--white-900);

			.mat-form-space {
				padding: 25px 0;

				label {
					font-size: 16px;
					font-family: Roboto, "Helvetica Neue", sans-serif;
					color: var(--gray-color);
				}
			}
		}
	}
}

.product-section {
	padding-top: 40px;

	.form-header {
		text-align: center;

		h2 {
			color: var(--primary-color);
			font-size: 20px;
			line-height: 1.25;

			&.font-danger {
				color: var(--font-danger);
			}
		}

		p {
			color: var(--gray-color);
			line-height: 1.25;
			font-size: 16px;
		}

		.useCodeLink {
			border-color: var(--primary-color);
			color: var(--primary-color);
			display: flex;
			letter-spacing: normal;
			width: fit-content;
			margin: 0 auto;
		}
	}

	.product-section-box {
		background-color: var(--white-color);
		box-shadow: 0 0 4px 0 rgba($black, 0.1);
		padding: 30px;
		border-radius: 10px;
		margin-top: 25px;

		.product-title {
			font-size: 30px;
			color: var(--primary-color);
			margin-bottom: 15px;
			margin-top: 20px;
			text-transform: capitalize;
			display: flex;
			align-items: center;

			.img-wrapper {
				max-width: 60px;
				margin-right: 20px;
				display: flex;
				justify-content: center;

				img {
					max-height: 25px;
				}
			}
		}

		.product-details-box {
			margin-bottom: 15px;

			.item {
				color: var(--gray-color);
				cursor: pointer;
			}
		}

		.m-form__group {
			.mat-form-border {
				border-bottom: 1px dashed var(--white-900);

				.mat-form-space {
					padding: 25px 0;

					label {
						font-size: 16px;
						font-family: Roboto, "Helvetica Neue", sans-serif;
						color: var(--gray-color);
					}
				}
			}

			h4 {
				font-size: 19px;
				font-weight: 500;

			}

			.mat-form-field-fluid {
				&.mat-radio-group {
					width: auto;
					display: flex;
				}
			}

			&.application-checkbox {
				text-align: left;
				padding: 40px 0 10px 0;
			}
		}

		.form-btn {
			display: flex;
			justify-content: flex-end;
			padding-top: 25px;
		}

		.checkbox-label-content {
			font-size: 12px;
			color: var(--gray-color);
		}

		&.manage-offer {
			.next-title {
				padding-bottom: 20px;
				border-bottom: 1px solid rgba(0, 0, 0, 0.07);
				margin-bottom: 30px;
				font-size: 15px;
			}

			.m-row--col-separator-xl {
				.m-widget1 {
					padding: 20px 0;
					text-align: center;

					.m-widget1__item {
						.m-widget1__title {
							font-size: 16px;
							font-weight: 500;
							margin-bottom: 0;
							color: var(--gray);
						}

						.m-widget1__desc {
							display: inline-block;
							margin-top: 0.21rem;
							font-size: 14px;
							font-weight: 300;
						}
					}
				}

				> div + div {
					border-bottom: 0;
					border-left: 1px solid var(--white-900);

					@media (max-width: 767px) {
						border-left: 0;
						border-top: 1px solid var(--white-900);
					}
				}
			}

			.pricing-content {
				background-color: var(--white-color);
				height: calc(100% - 20px);
				display: flex;
				justify-content: center;
				margin-bottom: 20px;

				.price-column-container {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					text-align: center;
					margin: 0;
					background-color: var(--white-color);
					border: 1px solid var(--white-600);
					width: 100%;

					.price-table-info {
						.price-table-head {
							color: var(--white-color);
							padding: 10px 0;
							background: var(--primary-color);
							position: relative;

							h2 {
								font-size: 19px;
							}

							&.price-1 {
								background-color: var(--blue-80)
							}

							&.bg-lower-payment {
								background-color: var(--primary-color);
							}

							&.bg-interest-saving {
								background-color: var(--primary-color);
							}

							&:after {
								position: absolute;
								content: '';
								border-left: 12px solid transparent;
								border-right: 12px solid transparent;
								border-top: 12px solid var(--primary-color);
								left: 50%;
								transform: translateX(-50%);
								bottom: -12px;
							}
						}

						.price-table-pricing {
							margin-top: 20px;

							h3 {
								font-size: 24px;
								position: relative;
								margin-top: 4px;
								color: var(--primary-color);
								margin-bottom: 0;

								.price-sign {
									font-size: 24px;
									margin-left: -15px;
									vertical-align: top;
									top: 15px
								}
							}

							p {
								margin-top: 0;
								color: var(--gray-800);
								font-size: 12px;
							}

							h4 {
								font-size: 19px;
								font-weight: 500;
							}

							.price-ribbon {
								position: absolute;
								top: 65px;
								right: -12px;
								width: 80px;
								font-size: 12px;
								text-transform: uppercase;
								font-weight: 600;
								padding: 5px;
								color: var(--white-color);
								background-color: var(--offer-ribbon);
								text-shadow: 0 1px 2px var(--gray-50);
								box-shadow: 0 2px 4px var(--gray-100);

								&:after {
									content: ' ';
									position: absolute;
									width: 0;
									height: 0;
									right: 0;
									top: 100%;
									border-width: 3px 6px;
									border-style: solid;
									border-color: var(--dark-green) transparent transparent var(--dark-green);
								}
							}
						}
					}

					.price-table-content {
						color: var(--gray-800);
						font-weight: 400;
						font-size: 1rem;

						.pricing-table-content-details {
							padding: 10px;

							i {
								color: var(--primary-blue)
							}

							.btn {
								margin: 5px 0;

								&-customize {
									padding: 0 36px;
								}
							}

							&:nth-child(odd) {
								background-color: var(--white-800);
							}

							&.customize-btn {
								background-color: transparent;
							}
						}
					}

					&:hover {
						box-shadow: 0 0 4px 0 var(--primary-blue);
					}

				}

				&:after,
				&:before {
					content: " ";
					display: table
				}

				&:after {
					clear: both
				}


				.pricing-title {
					border-bottom: 1px solid;
					border-color: var(--white-color);

					h1 {
						margin: 20px 0
					}
				}

				.mobile-padding {
					padding: 0 5px;

					i {
						margin-right: 20px
					}
				}

				@media (max-width: 767px) {
					margin-top: 10px;
				}
			}

			.price-table-footer {
				margin: 0 6px;

				.mobile-padding {
					padding: 15px 15px;

					h5 {
						font-size: 15px;
						color: var(--gray-color);
					}

					span {

						display: block;
						font-size: 12px;
						color: var(--gray-color);

						+ span {
							margin-top: 12px;
						}
					}
				}

				.mat-checkbox-layout {
					.mat-checkbox-label {
						color: var(--primary-color);
					}
				}
			}

			.customize-offer {
				min-height: 350px;

				.pricing-content {
					.price-column-container {
						position: relative;
						width: 100%;

						.price-table-info {
							.price-table-pricing {
								//position: absolute;
								//top: 50%;
								//transform: translateY(-50%);
								//width: 100%;
								//text-align: center;

								h4 {
									font-size: 18px;
									color: var(--gray-color);
								}
							}
						}
					}
				}
			}

			.active-panel {
				display: block;

				.manage-offer-widget-box {
					box-shadow: 0 0 4px 0 var(--primary-blue);
					border-radius: 10px;
					padding: 20px;
					margin-bottom: 20px;

					.m-cs-widget_content-title {
						font-size: 15px;
						color: var(--gray-color);
						font-weight: 400;
						text-align: center;
					}

					span {
						font-weight: 400;
						color: var(--gray-color);
						font-size: 12px;

						&.offer-price {
							color: var(--primary-color);
							font-weight: 700;
							font-size: 18px;
							line-height: 1.2;
						}
					}

					&.d-none d-sm-block d-md-none {
						display: none;
					}
				}
			}

			.m-widget5 {
				h5 {
					color: var(--gray-color);
					font-size: 15px;
				}
			}

		}

		@media (max-width: 576px) {
			box-shadow: none;
			padding: 0;
		}
	}

	.offer-card-price-slider {
		margin: 15px 0 25px;

		.ng5-slider-pointer {
			background-color: var(--primary-color);

			&.ng5-slider-active {
				&:after {
					background-color: var(--white-color);
				}
			}

			&:focus-visible {
				outline: none;
			}
		}

		.ng5-slider-bubble.ng5-slider-model-value {
			color: var(--primary-color);
			padding-bottom: 5px;
		}
	}

	.mat-button-toggle-group {
		.mat-button-toggle-appearance-standard {
			.mat-button-toggle-button {
				.mat-button-toggle-label-content {
					line-height: 35px;
					color: rgba($black, 0.38);
					font-size: 12px;
					font-weight: 600;
				}
			}

			&.mat-button-toggle-checked {
				.mat-button-toggle-button {
					background-color: var(--primary-color);
					border: 1px solid var(--primary-color);

					.mat-button-toggle-label-content {
						color: var(--white-color);
					}
				}
			}
		}
	}

	.myapp-page-application {
		.m--font-brand {
			color: var(--primary-color);
			font-weight: 500;
			margin-right: 15px;
			font-size: 16px;

			.cs-label {
				font-size: 14px;
				font-weight: 400;
				color: var(--light-gray);
			}
		}
	}

	.container-fluid {
		@media (max-width: 576px) {
			padding-left: 0;
			padding-right: 0;
		}
	}
}

.cross-sell-title {
	p {
		font-weight: 700;
		font-size: 16px;
	}
}

.pages {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	flex: 1;

	creditsnap-root {

		&.verticle {
			display: flex;

			creditsnap-progress-bar {
				height: 100%;

				.progressbar {
					height: 100%;
					margin: 0 auto;

					.container-fluid, .container-fluid .row, .container-fluid .position-relative {
						height: 100%;
					}

					.position-relative {
						min-height: 54vh;
						max-height: 55vh;

					}

					.progress {
						height: 100% !important;
						width: 5px !important;
						transform: translateX(-50%) !important;
						top: unset !important;
						left: 50%;

						.progress-bar {
							width: 5px;
						}
					}

					.progress-btn {
						flex-direction: column;
						height: 100%;
					}
				}
			}
		}
	}

	creditsnap-footer {
		flex: none;
	}
}

.pricing-option-cc {
	padding: 15px;
	transition: all .3s ease-in-out;
	border: 1px solid var(--white-300);
	border-radius: 15px;
	box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.2);
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: column;

	h3 {
		font-size: 21px;
		font-weight: 500;
	}

	ul {
		padding-left: 35px;

		li {
			font-size: 14px;
			font-weight: 400;
			color: var(--gray-color);
		}
	}

	.price {
		margin-top: auto;
		display: flex;
		flex-direction: column;
		align-items: center;

		a {
			color: var(--blue);
			font-size: 13px;
		}

		.mat-raised-button {
			border: 0;
			padding: 5px 15px;
			border-radius: 5px;
		}
	}

	&:hover {
		cursor: pointer;
		box-shadow: 0 2px 20px rgba($black, 0.3);
		transform: scale(1.025);
	}


}

.platinum-card-header {
	.header-card {
		padding-bottom: 15px;
		border-bottom: 1px solid rgba($black, 0.04);
		margin-bottom: 10px;

		h2 {
			font-size: 20px;
			color: var(--gray-color);
		}
	}

	ul {
		li {
			font-size: 15px;
			font-weight: 400;
			color: var(--gray-color);
		}
	}
}

.current-loan-balance {
	background-color: var(--white-color);
	border-radius: 8px;
	box-shadow: 0 0 4px 0 var(--primary-blue);
	padding: 15px;

	.m-cs-widget_content-title {
		color: var(--gray-label);
		font-size: 19px;
		font-weight: 600;
		padding-right: 10px;

		.vehicle_link {
			font-size: 13px;
			color: var(--blue);
			padding: 0;
			font-weight: 400;
		}
	}

	span {
		font-size: 12px;
		color: var(--gray-color);

		&.loan-balance {
			color: var(--primary-color);
			font-size: 18px;
		}
	}
}


.m-sub-splash-screen {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.m-cs-subheader {
		.m-cs-subheader__title {
			font-size: 1rem;
		}

		.m-cs-subheader_second-title {
			font-size: 0.8rem;
		}
	}

	mat-spinner {
		width: 40px !important;
		height: 40px !important;

		svg {
			width: 40px !important;
			height: 40px !important;
		}
	}
}

.select-loan-title {
	font-size: 1.3rem;
}

.mat-mdc-form-field {
	width: 100%;

	.mdc-text-field--filled {
		.mdc-floating-label--float-above {
			transform: translateY(-150%) scale(0.75);
		}

		&:not(.mdc-text-field--disabled) {
			background-color: transparent;
			padding: 0;
		}

		.mdc-line-ripple::after {
			border-bottom-color: var(--primary-color);
		}

		&.mdc-text-field--disabled {
			padding: 0;
			//background-color: #FFFFFF;
			.mdc-line-ripple::before {
				border-bottom-color: rgba(0, 0, 0, 0.3);
			}
		}
	}

	.mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)::after {
		display: none;
	}

	&:not(.mat-form-field-no-animations) {
		.mdc-text-field__input {
			height: 18px;
			margin-top: 10px;
		}
	}

	.mat-mdc-text-field-wrapper {
		&:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
			padding: 7px 0;
			min-height: 45px;
		}

		.mat-mdc-form-field-icon-prefix,
		.mat-mdc-form-field-icon-suffix {
			display: contents;
		}
	}

	.mat-mdc-form-field-error-wrapper {
		padding: 0;
	}

	.mat-mdc-form-field-focus-overlay {
		background-color: transparent;
	}

	.mat-mdc-form-field-hint-wrapper {
		padding: 0;

		.mat-mdc-form-field-hint {
			color: rgba(0, 0, 0, 0.54);
			line-height: 1.1;
		}
	}

	.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
		color: var(--primary-color);
	}

	.mat-mdc-select {
		.mat-mdc-select-arrow-wrapper {
			transform: translateY(5px);
		}
	}

	&.mat-focused.mat-primary .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
		color: var(--primary-color);
	}
}

.mat-error {
	color: #f44336;
}

.mat-mdc-select-value {
	margin-top: 7px;
}

.m--font-orange {
	color: var(--orange-color);
}

.btn-white {
	.mat-mdc-raised-button {
		color: rgba($black, .87);
		background-color: var(--white-color);

		&:not(:disabled) {
			color: rgba($black, .87);
			background-color: var(--white-color);
		}
	}
}

.mat-raised-button.mat-primary {
	background-color: var(--primary-color);
	color: var(--white-color);
	padding: 0.375rem 16px;
}

.mat-raised-button-gray {
	background-color: #adadad;
	color: var(--white-color);
	border-radius: 50px;
	overflow: hidden;
	padding: 10px 20px;
	height: 44px;
	margin-top: 5px;
	margin-left: 5px;
	margin-right: 5px;
	box-sizing: border-box;
	min-width: 64px;
	border: none;
	outline: none;
	line-height: inherit;
	user-select: none;
	overflow: visible;
	vertical-align: middle;
}

//.mat-button.mat-primary {
//	background-color: var(--primary-color);
//	color: var(--white-color);
//}

.mdc-button.mat-mdc-raised-button {
	letter-spacing: 0;
	padding: 0 16px;

	&.mat-primary {
		&:not(:disabled) {
			background-color: var(--primary-btn);
			color: var(--white-color);
		}
	}

	.mdc-button__label {
		position: unset;

	}

	&:disabled {
		.mdc-button__label {
			color: rgba(0, 0, 0, 0.26);

			.bar {

			}
		}
	}
}

.mat-mdc-progress-bar {
	--mdc-linear-progress-active-indicator-color: var(--primary-color);

	.mdc-linear-progress__buffer-bar {
		background-color: #cdcdcd;
	}
}

.mat-mdc-progress-spinner.mat-primary .mdc-circular-progress__determinate-circle,
.mat-mdc-progress-spinner.mat-primary .mdc-circular-progress__indeterminate-circle-graphic {
	stroke: var(--primary-color);
}

a {
	text-decoration: none;
	color: var(--primary-color);
}

.m--margin-bottom-20 {
	margin-bottom: 20px !important;
}

.mat-button.mat-primary.btn-primary-border, .mat-icon-button.mat-primary.btn-primary-border, .mat-stroked-button.mat-primary.btn-primary-border, .mat-stroked-button.mat-primary.btn-primary-border span {
	border-color: var(--primary-color);
	color: var(--primary-color) !important;
}

.mat-primary {
	&.mat-mdc-outlined-button:not(:disabled) {
		border-color: var(--primary-color);
		color: var(--primary-color);
	}
}

.w-fit {
	width: fit-content;
}

.word-break-all {
	word-break: break-all;
}

.white-space {
	white-space: normal;
}

.footer {
	background-color: var(--footer-dark);
	padding: 15px;
	margin-top: 50px;

	.footer-content {
		.footer-title {
			color: var(--white-color);
			display: flex;
			align-items: center;

			.img-wrapper {
				margin-right: 10px;
			}

			p {
				font-size: 13px;
			}
		}
	}
}

.copyright {
	background-color: var(--footer-light);
	padding: 10px;

	.copyright-details {
		display: flex;
		align-items: center;
		justify-content: space-between;

		p {
			color: var(--white-color);
			font-size: 12px;
		}

		a {
			color: var(--white-color);
			font-size: 12px;
			align-items: center;

			span {
				font-size: 12px;
			}
		}
	}
}

.add-on-product-card {
	.mat-mdc-card {
		box-shadow: 0 3px 1px -2px rgba($black, 0.2), 0 2px 2px 0 rgba($black, 0.14), 0 1px 5px 0 rgba($black, 0.12);
		display: flex;
		flex-direction: column;
		flex: 1;
		height: 100%;
		padding: 16px;

		.mat-mdc-card-header {
			.cs-product-title {
				color: var(--primary-color);
				font-size: 21px;
			}

			.cs-product-subtitle {
				font-size: 12px;
				color: rgba($black, 0.87);
			}
		}

		.mat-mdc-card-content {
			margin: 10px 0 16px;
			flex: 1 0 auto;

			span {
				font-size: 14px;

			}
		}

		.mat-mdc-card-footer {
			flex: none;
			margin: 5px;

			.mat-button.mat-primary.btn-link {
				color: var(--primary-color);
				min-width: 64px;
				line-height: 36px;
				background: transparent;
				border: 0;
				font-size: 14px;
				text-decoration: none;
				padding: 0 16px;
			}
		}
	}
}

.product-modal {
	.cs-product-modal-btn {
		.mat-button {
			line-height: 1.2;
			margin: 5px;
		}
	}

	.modal-footer-policy {
		p {
			font-size: 12px;
		}
	}
}

.cs-product-modal-card {
	height: 100%;
	display: flex;
	flex-direction: column;
	padding: 15px;
	border-radius: 5px;
	box-shadow: 0 0 3px 0 rgb(0 0 0 / 20%);

	.mat-mdc-radio-button {
		.mdc-form-field {
			align-items: start;
		}
	}

	.mat-mdc-card-content {
		margin-top: 10px;

		p {
			color: var(--black-95);
			margin-bottom: 0;
			font-size: 16px;

			span {
				color: var(--primary-color);
			}
		}
	}

	.mat-mdc-card-footer {
		padding-top: 0;
		margin: 5px;

		p {
			margin-bottom: 4px;
			font-size: 14px;
		}

		span {
			a {
				color: var(--primary-color);
				font-size: 14px;
			}
		}
	}
}

.font-brand {
	color: var(--primary-color) !important;
}

.trade-section {
	.trade-box {
		background-color: var(--white-color);
		box-shadow: 0 0 4px 0 rgb(0 0 0 / 20%);
		padding: 30px;
	}
}

.offer-section {
	.offer-box {
		background-color: var(--white-color);
		box-shadow: 0 0 4px 0 rgb(0 0 0 / 20%);
		padding: 30px;
	}
}

.pre-qualifide {
	.offer-box {
		background-color: var(--white-color);
		box-shadow: 0 0 4px 0 rgb(0 0 0 / 20%);
		padding: 30px;
	}
}

.additional-info-pages {
	.additional-box {
		background-color: var(--white-color);
		box-shadow: 0 0 4px 0 rgb(0 0 0 / 20%);
		padding: 30px;
	}
}

.add-on-section {
	.add-on-box {
		background-color: var(--white-color);
		box-shadow: 0 0 4px 0 rgb(0 0 0 / 20%);
		padding: 30px;
	}
}

.thankyou-pages {
	.thank-you-title {
		background-color: var(--white-color);
		box-shadow: 0 0 37px -8px var(--primary-blue);
		padding: 30px;
		border-radius: 20px;
	}
}

.m--regular-font-size-sm1 {
	font-size: 10px;
}

.mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__background::before {
	background-color: var(--primary-color) !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle,
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
	border-color: var(--primary-color) !important;
}

.mat-mdc-radio-button.mat-accent {
	label {
		cursor: pointer;
	}

	.mdc-radio {
		padding: 0;
	}
}

.mat-mdc-checkbox {
	padding-bottom: 15px;

	.mdc-form-field {
		white-space: normal;
		font-size: 14px;

		.mdc-checkbox {
			padding: 0;
			margin-right: 5px;


			.mat-mdc-checkbox-touch-target, .mdc-checkbox__native-control {
				height: 100%;
				width: 100%;
			}

			.mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
				background-color: var(--primary-color);
				border-color: var(--primary-color);

				.mdc-checkbox__checkmark {
					color: var(--white-color);
				}
			}

			.mdc-checkbox__background {
				height: 16px;
				width: 16px;
				top: 0;
				left: 0;
			}

			&:hover {
				.mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
					background-color: var(--primary-color);
					border-color: var(--primary-color);
				}
			}

			//.mat-mdc-checkbox .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
			//.mat-mdc-checkbox .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
			//.mat-mdc-checkbox .mdc-checkbox:hover .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background
		}


		label {
			line-height: 1;

			.content-text {
				font-size: 16px;
				margin-bottom: 0;
				height: 40px;
				overflow: hidden;
				display: inline-block;
				color: var(--gray-color);
				line-height: 1.25;

				ul {
					margin-bottom: 0
				}

				&.showFull {
					overflow: auto;
					height: auto;
				}
			}
		}
	}
}


.mat-fab.mat-primary, .mat-flat-button.mat-primary, .mat-mini-fab.mat-primary, .mat-raised-button.mat-primary {
	background-color: var(--primary-btn);
	color: var(--white-color);
}

//
//.mat-form-field.mat-focused .mat-form-field-label,
//.mat-form-field.mat-focused .mat-placeholder-required {
//	color: var(--primary-color);
//}
//
//.mat-form-field.mat-focused .mat-form-field-ripple {
//	color: var(--primary-color);
//	background-color: var(--primary-color);
//}
//
//.mat-input-element {
//	color: var(--primary-color);
//}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
	color: var(--primary-color);
}

.trade-radio {
	color: var(--gray-color);
}

.btn:hover {
	color: var(--white-color);
}

.ng5-slider {
	.ng5-slider-pointer {
		background-color: var(--primary-color);

		&.ng5-slider-active {
			&:after {
				background-color: var(--white-color) !important;
			}
		}

		&:focus-visible {
			outline: none;
		}
	}
}

.cc-type-info {
	background-color: var(--primary-color);
	color: var(--white-color);

	a.cc-btn {
		color: var(--black-90);
		background-color: var(--white-color);
		border: 0;
	}
}

.mat-form-field-required-marker {
	display: none;
}


@media only screen and (max-width: 575px) {
	.product-section {
		padding-top: 20px;

		.form-header {
			h2 {
				font-size: 18px;
			}

			p {
				font-size: 15px;
			}
		}

		.product-section-box {
			margin-top: 0;

			.m-form__group {
				&.application-checkbox {
					padding-top: 25px;
				}

				.mat-form-border {
					.mat-form-space {
						padding: 10px 5px 15px;
					}
				}
			}
		}
	}
}


/* SNACK BAR CSS */
.success-snackbar {
	background: #34bfa3;
	color: white;
	border-radius: 5px;
	justify-content: center;

	&.mat-mdc-snack-bar-container .mdc-snackbar__surface {
		background: #34bfa3;
	}
}

.error-snackbar {
	background: #ff1b41;
	color: white;
	border-radius: 5px;
	justify-content: center;

	&.mat-mdc-snack-bar-container .mdc-snackbar__surface {
		background: #ff1b41;
	}
}

.warning-snackbar {
	background: #ffb822;
	color: white;
	border-radius: 5px;
	justify-content: center;

	&.mat-mdc-snack-bar-container .mdc-snackbar__surface {
		background: #ffb822;
	}
}

.otp-modal {
	.modal-details {
		text-align: center;

		h2 {
			font-weight: 700;
			color: var(--primary-color);
			margin-bottom: 10px;
			font-size: 20px;
		}

		h6 {
			color: var(--gray-800);
			font-size: 14px;
		}

		ngx-otp-input {
			.ngx-otp-input-container {
				justify-content: center;
				margin-top: 70px;

				.ngx-otp-input {
					border-radius: 0;
					border-width: 0 0 2px 0;
					border-color: var(--gray-100);
					max-width: 55px;
					width: 100%;

					&.otp-error-class {
						border-color: var(--font-danger) !important;
					}
				}
			}
		}

		.validate-button {
			font-size: 16px;
			padding: 0 26px;
		}
	}
}

//.mat-mdc-dialog-container {
//	.mdc-dialog__container {
//		.mat-mdc-dialog-surface {
//			padding: 20px;
//			.mat-mdc-dialog-title {
//				&:before {
//					height: 0;
//				}
//			}
//		}
//	}
//}

.saving-type-radio {
	position: relative;
	height: 100%;
	padding-bottom: 20px;

	.mat-mdc-radio-button {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		padding: 15px 10px;
		border-radius: 10px;
		border: 1px solid #b8b8b8;
		transition: all 0.3s ease-in;

		.mdc-form-field {
			.mdc-radio {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				opacity: 0;

				.mat-mdc-radio-touch-target {
					width: 100%;
					height: 100%;
				}
			}
		}

		.img-wrapper {
			max-width: 40px;
			min-width: 40px;
			height: 40px;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
			}
		}

		&.mat-mdc-radio-checked {
			border-color: var(--primary-color);
			box-shadow: 0 0 8px 1px var(--primary-color);
			transform: scale(1.05)
		}

	}
}


.text-primary {
	color: var(--primary-color) !important;
}


.mat-mdc-form-field-has-icon-prefix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
	.mat-mdc-form-field-icon-prefix span {
		padding-left: 0.5rem;
	}

	.mat-mdc-form-field-icon-suffix span {
		padding-right: 0.5rem;
	}
}

.mat-mdc-form-field-has-icon-prefix.mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
	padding-left: 0;
}

@media (max-width: 476px) {
	.rent-payment-input {
		margin-bottom: 3rem;
	}
}

.mdc-dialog {
	.mdc-dialog__title::before {
		height: 36px;
	}
}

/*=================================== STYLE FOR FLOW 2 ==============================*/
creditsnap-flow-2 {
	& .mat-mdc-form-field-has-icon-prefix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
		.mat-mdc-floating-label {
			padding-left: 25px;
		}
	}
}

/*=================================== STYLE FOR FLOW 3 ==============================*/
.creditsnap-flow-3 {
	.mat-mdc-form-field {
		&:not(.mat-form-field-no-animations) {
			.mdc-text-field__input {
				margin-top: 0;
				position: relative;
				bottom: 1px;
			}
		}

		.mat-mdc-text-field-wrapper {
			border-radius: 4px;
		}
	}

	.mat-mdc-form-field-has-icon-prefix .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
		.mat-mdc-floating-label {
			padding-left: 22px;
		}
	}

	.mat-mdc-text-field-wrapper {
		.mat-mdc-form-field-flex .mat-mdc-floating-label {
			//top: 22px;
		}

		&.mdc-text-field--outlined {
			.mat-mdc-form-field-infix {
				padding-bottom: 6px;
			}

			.mdc-notched-outline--upgraded .mdc-floating-label--float-above {
				--mat-mdc-form-field-label-transform: translateY(-27.75px) scale(var(--mat-mdc-form-field-floating-label-scale, 0.75))
			}
		}
	}

	.mat-mdc-form-field-icon-prefix {
		position: relative;
		top: -5px;
	}

	.duration-input-container {
		position: relative;
		padding: 0 10px;
	}

	.product-section {
		.product-section-box {
			box-shadow: none;

			.price-column-container {
				border-radius: 10px;
			}

			.price-table-head {
				border-radius: 10px 10px 0 0;
			}
		}
	}

	.mat-form-field-appearance-outline .mat-form-field-outline {
		border-color: transparent !important; /* Transparent border by default */
	}

	.mdc-notched-outline__notch {
		width: 100%!important;
	}

	creditsnap-phone-input {
		mat-intl-tel-input {
			display: block;
			margin: 0 !important;

			.mdc-button.mat-mdc-button {
				box-shadow: none !important;
				padding: 0;
				height: auto;
				margin-top: -5px !important;
			}
		}

		.mdc-floating-label--float-above .phone-input-label {
			padding-left: 0 !important;
		}

		.phone-input-label.mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
			//left: 98px;
		}
	}

	.mdc-floating-label--float-above{
		padding: 0 10px;
	}

	mat-intl-tel-input {
		margin-top: -3px !important;

		.mdc-button.mat-mdc-button {
			height: unset;
			margin: 0 10px 0 0;
			font-size: 16px;

			.mat-mdc-button-touch-target {
				height: unset;
			}
		}
	}

	.mat-mdc-form-field .mdc-floating-label.mdc-floating-label--float-above {
		background-color: rgb(251 251 251 / 93%);
	}

	+ .mat-mdc-form-field:not(.mat-form-field-no-animations) .mdc-text-field__input,
	+ .mat-mdc-select .mat-mdc-select-value{
		margin-top: 0!important;
	}

	.mdc-text-field--outlined .mdc-notched-outline,
	.mdc-notched-outline,
	.mdc-notched-outline__trailing,
	.mdc-notched-outline__notch,
	.mdc-notched-outline__leading{
		border-color: transparent !important;
	}

	.mat-focused, .ng-touched{
		& .mdc-text-field--outlined .mdc-notched-outline,
		& .mdc-notched-outline{
			border: none !important;
			border-radius: 4px;
			box-shadow: 0 0 2px var(--primary-color);
		}

		& .mat-mdc-form-field-has-icon-prefix{
			.mat-mdc-text-field-wrapper {
				.mat-mdc-form-field-flex {
					.mat-mdc-form-field-icon-prefix{
						span{
							top: -1px;
							position: relative;
						}
					}
				}
			}
		}
	}

	:not(.ng-touched){
		& .mat-mdc-form-field-has-icon-prefix{
			.mat-mdc-text-field-wrapper {
				.mat-mdc-form-field-flex {
					.mat-mdc-form-field-icon-prefix{
						span{
							top: -7px;
							position: relative;
						}
					}
				}
			}
		}

		.mat-mdc-text-field-wrapper {
			.mat-mdc-form-field-flex {
				.mat-mdc-floating-label{
					top: 20px;
				}
			}
		}
	}
}

