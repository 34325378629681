/* You can add global styles to this file, and also import other style files */

a {
	text-decoration: none;
}

:root {
	--primary: #39b34d;
	--mdc-theme-primary: #39b34d;
	--primary-color: #39b34d;
	--primary-btn: #39b34d;
}

body {
	font-family: 'PT Sans', sans-serif;
}


.mdc-button.mat-mdc-raised-button, .mdc-button.mdc-button--outlined {
	border-radius: 50px;
	overflow: hidden;
	padding: 10px 20px;
	height: 44px;
	font-size: 16px;

	@media (max-width: 576px) {
		min-width: 50%;
		margin: 5px auto;
	}
}

.mat-mdc-form-field:not(.mat-form-field-no-animations) .mdc-text-field__input {
	margin-top: 0;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading, .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch, .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
	border-color: var(--primary-color);
}

.mat-datepicker-toggle {
	position: absolute;
	right: 0;
	top: -1px;
}

.rent-mortgage-payment .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
	padding-left: 1rem;
}


.product-section {
	@media (max-width: 576px) {
		.container-fluid {
			padding-left: var(--bs-gutter-x, 0.75rem);
			padding-right: var(--bs-gutter-x, 0.75rem);
		}

		.custom-container {
			padding-left: 0;
			padding-right: 0;
		}

		.multi-step-form {
			.form-step {
				border-radius: 0;
				box-shadow: none;
			}
		}
	}

}

.mat-mdc-select .mat-mdc-select-value {
	margin-top: 0;
}

.mat-mdc-form-field .mat-mdc-select .mat-mdc-select-arrow-wrapper {
	transform: unset;
}

.mat-datepicker-toggle {
	.mat-mdc-button-base {
		background-color: transparent !important;
		color: rgba(0, 0, 0, 0.54) !important;
	}
}

.product-section {
	.product-section-box {
		.m-form__group {
			h4 {
				font-size: 20px;
				font-weight: 500;
				color: var(--primary-color);
				text-align: center;
			}
		}
	}

	.form-header h2 {
		font-size: 25px;
		font-weight: 600;
	}
}

@media (max-width: 576px) {
	.btn {
		width: 100%;

		&.step-button {
			padding: 0;

			.mdc-button.mat-mdc-raised-button {
				width: 100%;
			}
		}
	}

}
